<template>
<b-overlay
  :show="loading"
  rounded="lg">
  <div>
    <b-breadcrumb style="margin-bottom:20px">
      <b-breadcrumb-item to="/">
        <feather-icon
          icon="HomeIcon"
          size="16"
          class="align-text-top" />
      </b-breadcrumb-item>
      <b-breadcrumb-item
        to="/delivery-orders/daikin"
        v-if="goBack === 'do-daikin'">List Delivery Order Daikin</b-breadcrumb-item>
      <b-breadcrumb-item
        to="/delivery-orders/sumitomo"
        v-if="goBack === 'do-sumitomo'">List Delivery Order Sumitomo</b-breadcrumb-item>
      <b-breadcrumb-item
        to="/self-collect/daikin"
        v-if="goBack === 'sc-daikin'">List Self Collect Daikin</b-breadcrumb-item>
      <b-breadcrumb-item
        to="/self-collect/sumitomo"
        v-if="goBack === 'sc-sumitomo'">List Self Collect Sumitomo</b-breadcrumb-item>
      <b-breadcrumb-item
        to="/shipping"
        v-if="goBack === 'shipping'">List Shipping</b-breadcrumb-item>
      <b-breadcrumb-item
        to="/return-delivery/daikin"
        v-if="goBack === 'return-daikin'">List Return Delivery Daikin</b-breadcrumb-item>
      <b-breadcrumb-item
        to="/return-delivery/sumitomo"
        v-if="goBack === 'return-sumitomo'">List Return Delivery Sumitomo</b-breadcrumb-item>
      <b-breadcrumb-item
        to="/manual-return-delivery/daikin"
        v-if="goBack === 'manual-return-daikin'">List Return Delivery Daikin</b-breadcrumb-item>
      <b-breadcrumb-item
        to="/manual-return-delivery/sumitomo"
        v-if="goBack === 'manual-return-sumitomo'">List Return Delivery Sumitomo</b-breadcrumb-item>
      <b-breadcrumb-item
        to="/retrigger-api"
        v-if="goBack === 'resend'">List Retrigger API</b-breadcrumb-item>
      <b-breadcrumb-item active>Unpick and Scan</b-breadcrumb-item>
    </b-breadcrumb>
    <b-card no-body>
      <!-- Header conditional-->
      <b-card-header v-if="pageone">
        <!-- 1. Common information this DO-->
        <h4 v-if="goBack === 'shipping'">Shipping</h4>
        <h4 v-if="goBack === 'resend'">Retrigger API</h4>
        <h4 v-if="
              doData.shippingPoint === '1001' &&
                (goBack === 'do-daikin' ||
                  goBack === 'sc-daikin' ||
                  goBack === 'return-daikin')
            ">
          Daikin Warehouse
        </h4>
        <h4 v-if="
              doData.shippingPoint === '1003' &&
                (goBack === 'do-sumitomo' ||
                  goBack === 'sc-sumitomo' ||
                  goBack === 'return-sumitomo')
            ">
          Sumitomo Warehouse
        </h4>
      </b-card-header>

      <b-card-header v-if="pagetwo">
        <!-- 2. List Item information this DO-->
        <h4 v-if="goBack === 'shipping'">Shipping Item List</h4>
        <h4 v-if="goBack === 'resend'">Retrigger API Item List</h4>
        <h4 v-if="
              doData.shippingPoint === '1001' &&
                (goBack === 'do-daikin' ||
                  goBack === 'sc-daikin' ||
                  goBack === 'return-daikin')
            ">
          Daikin Warehouse Item List
        </h4>
        <h4 v-if="
              doData.shippingPoint === '1003' &&
                (goBack === 'do-sumitomo' ||
                  goBack === 'sc-sumitomo' ||
                  goBack === 'return-sumitomo')
            ">
          Sumitomo Warehouse Item List
        </h4>
      </b-card-header>

      <b-card-header v-if="pagethree">
        <!-- 3. Detail List Item Scanned information this DO-->
        <h4 v-if="goBack === 'shipping'">Shipping Item Detail</h4>
        <h4 v-if="goBack === 'resend'">Retrigger API Item Detail</h4>
        <h4 v-if="
              doData.shippingPoint === '1001' &&
                (goBack === 'do-daikin' ||
                  goBack === 'sc-daikin' ||
                  goBack === 'return-daikin')
            ">
          Daikin Warehouse Item Detail
        </h4>
        <h4 v-if="
              doData.shippingPoint === '1003' &&
                (goBack === 'do-sumitomo' ||
                  goBack === 'sc-sumitomo' ||
                  goBack === 'return-sumitomo')
            ">
          Sumitomo Warehouse Item Detail
        </h4>
      </b-card-header>
      <!-- End of Header conditional-->
      <b-card-body>
        <b-modal
          v-model="modalManual"
          title="Manual Entry">
          <b-container>
            <b-row>
              <b-col
                cols="12"
                v-if="!startManual">
                <p>Instructions</p>

                <img :src="manualImage" style="width: 100%; height: auto" />
                <ul style="list-style: decimal; padding: 0 0 0 10px;">
                  <li>Click Start</li>
                  <li>Please look code below the barcode</li>
                  <li>
                    Key in 14 digit code into the input box and click on check
                    button
                  </li>
                  <li>Proceed to add the rest of codes</li>
                  <li>Click save to finish adding</li>
                </ul>

                <div
                  class="my-3"
                  style="display: flex; justify-content: center">
                  <b-button
                    variant="success"
                    @click="openManualScan">Start</b-button>
                </div>
              </b-col>

              <b-col
                cols="12"
                v-if="startManual">
                <div class="d-flex">
                  <b-form-input
                    ref="manualScan"
                    v-on:keyup.enter="verifySerial"
                    class="verifyButton"
                    v-model="barcode"
                    maxlength="14"
                    placeholder="Input Barcode"
                    autofocus></b-form-input>
                  <b-button
                    variant="success"
                    class="ml-1"
                    @click="verifySerial">Add</b-button>
                </div>

                <p class="my-2">
                  Key in 14 digit code into the input box exclude 2 asterisk *
                </p>
              </b-col>
            </b-row>
          </b-container>

          <template #modal-footer>
            <div class="w-100 d-flex justify-content-center align-items-center"></div>
          </template>
        </b-modal>
        <b-modal
          v-model="modalScan"
          title="Scan Barcode"
          @close="clearBarcode">
          <b-container>
            <b-row>
              <b-col
                cols="12"
                v-if="!startScan">
                <p>Instructions</p>

                <img :src="barcodeImage" style="width: 100%; height: auto" />

                <ul style="list-style: decimal; padding: 0 0 0 10px;">
                  <li>Click Start</li>
                  <li>
                    Please look for barcode that has 14 digits number below it
                  </li>
                  <li>If barcode valid you will receive success message</li>
                  <li>Proceed to add the rest of codes</li>
                  <li>Click save to finish adding</li>
                </ul>

                <div
                  class="my-3"
                  style="display: flex; justify-content: center">
                  <b-button
                    variant="success"
                    @click="startScan = true">Scan</b-button>
                </div>
              </b-col>

              <b-col
                cols="12"
                v-if="startScan">
                <!-- <div class="barcode-scanner">
                              <v-quagga
                                :onDetected="onDecode"
                                :readerTypes="['code_39_reader']"/>
                              </div> -->

                <!-- <div class="barcode-scanner">
                              <v-quangga-image
                                :onDetected="onDecode"
                                :readerTypes="['code_39_reader']"/>
                              </div> -->

                <zxing-scanner @decode="(code) => (barcode = code)" />

                <b-form-input
                  v-on:keyup.enter="verifySerial"
                  v-model="barcode"
                  type="text"
                  placeholder="Item Barcode"
                  maxlength="14"
                  class="my-1 verifyButton"
                  disabled
                  style="text-color: #ffffff"></b-form-input>

                <div style="display: flex; justify-content: center">
                  <b-button
                    @click="verifySerial"
                    :disabled="barcode === null"
                    variant="primary">
                    Add
                  </b-button>
                </div>
              </b-col>

              <b-col
                cols="12"
                title="Manual Entry"
                v-if="startScan"
                class="mt-2">
                <b-table
                  :fields="addItemFields"
                  :items="backendItems"
                  responsive="sm">
                  <template #cell(actions)="row">
                    <b-button
                      size="sm"
                      @click="deleteItem(row)"
                      class="mr-1">
                      Delete
                    </b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-container>

          <template #modal-footer>
            <div class="w-100 d-flex justify-content-center align-items-center"></div>
          </template>
        </b-modal>

        <b-modal
          v-model="modalSkip"
          title="Skip Scan">
          <b-container>
            <b-row>
              <b-col cols="12">
                <div class="d-flex">
                  Are you sure want to skip scan this
                  {{ getItem(selectData).itemModel }} item?
                </div>
              </b-col>
            </b-row>
          </b-container>

          <template #modal-footer>
            <div class="w-100 d-flex justify-content-center align-items-center">
              <b-button
                variant="success"
                class="ml-1"
                @click="verifySkip">Confirm</b-button>
            </div>
          </template>
        </b-modal>

        <!-- Modal Edit/Cancel Handler -->

        <b-modal
          v-model="modalHandler"
          v-b-modal.modal-lg
          :no-close-on-backdrop="true">
          <template #modal-header="{ close }">

            <h5>Edit/ Cancel Handler</h5>
          </template>
          <b-container>
            <b-row>
              <b-col cols="12">
                <div class="d-flex">
                  {{this.handlerMessage}}
                </div>
              </b-col>
            </b-row>
          </b-container>

          <template #modal-footer>
            <b-row class="w-100 d-flex justify-content-center align-items-center">
              <b-button
                variant="success"
                class="ml-1"
                @click="handlerOnClick">
                Go Back
              </b-button>
            </b-row>
          </template>
        </b-modal>

        <b-modal
          v-model="modalError"
          v-b-modal.modal-lg>
          <template #modal-header="{ close }">

            <h5>Error in Delivery Order Inventory, please contact Support to solve this issue</h5>
          </template>
          <b-container>
            <b-row>
              <b-col cols="12" v-for="(item, index) in errorList" class="mb-1" :key="index">
                <div class="d-flex text-break" style="word-wrap: break-word;">
                  {{item.message}}
                </div>
              </b-col>
            </b-row>
          </b-container>

          <template #modal-footer>
            <b-row class="w-100 d-flex justify-content-center align-items-center">
              <b-button
                variant="failed"
                class="ml-1"
                @click="closeModal">
                Close
              </b-button>
            </b-row>
          </template>

        </b-modal>

        <!-- End of Modal Edit/Cancel Handler -->

        <b-col cols="12">
          <b-row>
            <b-button
              variant="primary"
              @click="$router.go(-1)">
              <feather-icon
                size="16"
                icon="HomeIcon" /> Main Menu</b-button>
          </b-row>
        </b-col>

        <b-col
          cols="12"
          class="mt-2"
          v-if="pageone">
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>SAP DO Number</label>
                <p class="font-weight-bold">{{ doData.sapDo }}</p>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>SAP SO Number</label>
                <p class="font-weight-bold">{{ doData.sapSo }}</p>
              </div>
            </b-col>

            <b-col
              cols="12"
              v-show="doData.status === 'Approved'">
              <div class="d-flex flex-column">
                <label>Return Reason</label>
                <p class="font-weight-bold">{{ doData.reason }}</p>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Delivery Date</label>
                <p class="font-weight-bold">{{ dates(doData.deliveryDate) }}</p>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Delivery Time</label>
                <p class="font-weight-bold">{{ showTime(doData.priority) }}</p>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Delivery Address</label>
                <p class="font-weight-bold">
                  {{ doData.deliveryAddress1 }}
                </p>
                <p class="font-weight-bold">
                  {{ doData.deliveryAddress2 }}
                </p>
                <p class="font-weight-bold">
                  {{ doData.deliveryAddress3 }}
                </p>
                <p class="font-weight-bold">{{ doData.deliveryCity }}</p>
                <p class="font-weight-bold">{{ doData.deliveryPostalCode }}</p>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Business Partner</label>
                <p class="font-weight-bold">{{ doData.CustomerName1 +" "+doData.CustomerName2 }}</p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Customer Information</label>
                <p class="font-weight-bold">{{ doData.CustomerName3 }}</p>
                <p class="font-weight-bold">{{ doData.phone }}</p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Customer PO</label>
                <p class="font-weight-bold">
                  {{ doData.customerPo.includes('-') && doData.customerPo.startsWith('RSP') ? doData.customerPo.substring(12) : doData.customerPo }}
                </p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>RSP Order Number</label>
                <p class="font-weight-bold">
                  {{ doData.customerPo.includes('-') && doData.customerPo.startsWith('RSP') ? doData.customerPo.substring(0, 11) : '' }}
                </p>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="mb-2">
              <b-button
                size="md"
                variant="primary"
                @click="toPage()"
                v-if="goBack !== 'other'">
                <feather-icon size="16" />
                Back to Previous
              </b-button>

            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-2">
              <b-button
                size="md"
                @click="nextPage"
                variant="primary">Next</b-button>
            </b-col>
          </b-row>
        </b-col>
        <!-- page two -->
        <b-col
          cols="12"
          class="mt-2"
          v-if="pagetwo">
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>SAP DO Number</label>
                <p class="font-weight-bold">{{ doData.sapDo }}</p>
              </div>
            </b-col>
            <b-col cols="12">
              <h5>Select Item</h5>
            </b-col>
            <b-col cols="12">
              <b-table
                :fields="scanFields"
                :items="doData.DO_details"
                show-empty
                responsive
                :stacked="stackedStatus">
                <template #cell(actions)="row">
                  <!-- Processing selected item with select Item
                                              Commenting, does not comply with auto update load-->
                  <!--
                                      <b-button v-if="doData.status==='Approved'" 
                                          @click="selectItem(row)" 
                                          v-show="getSummary(row.item.material).scanQty != row.item.returnBarcode.length" variant="primary">
                                          Select Item
                                      </b-button>
                                      <b-button v-else @click="selectItem(row)" 
                                          v-show="getSummary(row.item.material).scanQty < (row.item.qty-row.item.qtyUpdate)" variant="primary">
                                          Select Item To Edit
                                      </b-button>
                                      -->

                  <b-button
                    v-if="doData.status === 'Approved'"
                    @click="selectItemByIndex(row.index)"
                    v-show="
                        getReturnSummary(row.item.material).unscanned !=
                          row.item.returnBarcode.length
                      "
                    variant="primary">
                    Select Item
                  </b-button>
                  <b-button
                    v-else
                    @click="selectItemByIndex(row.index)"
                    v-show="
                        getEditSummary(row.item.material).scanQty <
                          row.item.qty - row.item.qtyUpdate
                      "
                    variant="primary">
                    Select Item To Edit
                  </b-button>
                </template>

                <template #cell(material)="row">
                  {{ getItem(row.item.material).itemModel }}
                </template>
                <template #cell(desc)="row">
                  {{ getItem(row.item.material).description }}
                </template>
                <template #cell(qty)="row">
                  <!-- original quantity of item -->
                  <!-- If approved, unpick process for return-->

                  <p v-if="doData.status === 'Approved'">
                    {{ getReturnSummary(row.item.material).qty }}
                  </p>
                  <!-- If not, unpick process for edit DO-->

                  <p v-else>{{ row.item.qtyUpdate }}</p>
                </template>
                <template #cell(newQty)="row">
                  <!-- amount of barcode to be unscanned -->
                  <!-- If approved, unpick process for return-->

                  <p v-if="doData.status === 'Approved'">
                    {{ getReturnSummary(row.item.material).toUnscan }}
                  </p>
                  <!-- If not, unpick process for edit DO-->

                  <p v-else>{{ row.item.qty - row.item.qtyUpdate }}</p>
                </template>
                <template #cell(scan)="row">
                  <!-- number of barcode unscanned -->
                  <!-- If approved, unpick process for return-->
                  <p v-if="doData.status === 'Approved'">
                    {{ getReturnSummary(row.item.material).unscanned }}
                  </p>
                  <!-- If not, unpick process for edit DO-->
                  <p v-else>{{ getEditSummary(row.item.material).scanQty }}</p>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="8">
              <b-button
                @click="backPage"
                variant="primary">Back</b-button>
            </b-col>
            <b-col
              v-if="doData.status === 'Approved'"
              cols="4">
              <b-button
                :disabled="disableReturnCompleteButton"
                @click="scanComplete"
                class="float-right"
                variant="success">Return Completed</b-button>
            </b-col>
            <b-col
              v-else
              cols="4">
              <b-button
                :disabled="editEnabled()"
                @click="scanComplete"
                class="float-right"
                variant="success">Edit Completed</b-button>
            </b-col>
          </b-row>
        </b-col>
        <!-- end page two -->

        <!-- page three -->
        <b-col
          cols="12"
          class="mt-2"
          v-if="pagethree">
          <!-- 3. Detail List Item Scanned information this DO-->
          <!--  row.item in page 2 == seletScan in page 3-->
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>SAP DO Number</label>
                <p class="font-weight-bold">{{ doData.sapDo }}</p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Model No</label>
                <p class="font-weight-bold">
                  {{ getItem(selectScan.material).itemModel }}
                </p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Description</label>
                <p class="font-weight-bold">
                  {{ getItem(selectScan.material).description }}
                </p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Original QTY</label>
                <!-- If approved, unpick process for return-->
                <p
                  v-if="doData.status === 'Approved'"
                  class="font-weight-bold">
                  {{ getReturnSummary(selectScan.material).qty }}
                </p>
                <!-- If not, unpick process for edit DO-->
                <p
                  v-else
                  class="font-weight-bold">{{ selectScan.qty }}</p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>New Qty</label>
                <p
                  v-if="doData.status === 'Approved'"
                  class="font-weight-bold">
                  {{
                      getReturnSummary(selectScan.material).qty -
                        getReturnSummary(selectScan.material).toUnscan
                    }}
                </p>
                <!-- If not, unpick process for edit DO-->
                <p
                  v-else
                  class="font-weight-bold">
                  {{ selectScan.qtyUpdate }}
                </p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column center-on-mobile">
                <label>Unscanned</label>
                <p
                  v-if="doData.status === 'Approved'"
                  class="font-weight-bold">
                  {{ getReturnSummary(selectScan.material).unscanned }}/{{
                      getReturnSummary(selectScan.material).toUnscan
                    }}
                </p>
                <!-- If not, unpick process for edit DO-->
                <!-- <p
   v-else
   class="font-weight-bold">{{getEditSummary(selectScan.material)}}</p> -->
                <p
                  v-else
                  class="font-weight-bold">
                  {{ getEditSummary(selectScan.material).scanQty }}
                </p>
              </div>
            </b-col>
          </b-row>
          <div
            style="height:90px"
            class="mobile-version">
            <b-row class="mobile-version">
              <b-col cols="6">
                <b-button
                  style="height:75px; padding : 30px"
                  @click="doUnscan(selectScan.material)"
                  :disabled="btnScan"
                  class="w-100 d-flex justify-content-center">Camera</b-button>
              </b-col>
              <b-col cols="6">
                <b-button
                  style="height:75px; padding : 30px"
                  @click="testDoUnscan(selectScan.material)"
                  :disabled="btnScan"
                  class="w-100 d-flex justify-content-center"
                  variant="primary">Un Scan</b-button>
              </b-col>
            </b-row>
          </div>
          <b-row class="mobile-version">
            <b-col>
              <b-button
                style="height:75px; padding : 30px"
                @click="skipUnscan(selectScan)"
                :disabled="btnSkip"
                class="w-100 d-flex justify-content-center"
                variant="danger">Skip Un Scan</b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="3"
              class="adjust-on-mobile">
              <b-button
                @click="doUnscan(selectScan.material)"
                :disabled="btnScan"
                class="w-100 d-flex justify-content-center desktop-version">Camera</b-button>
            </b-col>
            <b-col
              md="3"
              class="adjust-on-mobile">
              <b-button
                @click="testDoUnscan(selectScan.material)"
                :disabled="btnScan"
                class="w-100 d-flex justify-content-center desktop-version"
                variant="primary">Un Scan</b-button>
            </b-col>
            <b-col
              md="3"
              class="adjust-on-mobile">
              <b-button
                @click="skipUnscan(selectScan)"
                :disabled="btnSkip"
                class="w-100 d-flex justify-content-center desktop-version"
                variant="danger">Skip Un Scan</b-button>
            </b-col>
          </b-row>
          <!-- <b-col cols="12" v-if="doData.status==='Approved'" #cell(barcode)="row" > -->
          <b-row>
            <b-col
              style="margin-top:20px; margin-bottom:20px"
              cols="12"
              v-if="doData.status === 'Approved'">
              <b-table
                :fields="itemFields"
                :items="listToBeReturned"
                show-empty
                responsive
                :stacked="stackedStatus">
                <template #cell(barcode)="row">
                  {{ row.item }}
                </template>
              </b-table>
              <!-- v1, no longer used -->
              <!--
                              <b-table
                                  :fields="itemFields"
                                  :items="listItemScan"
                                  show-empty
                                  responsive
                                  :stacked= stackedStatus>
                                  <template #cell(barcode)="row" >
                                      {{ row.item.returnBarcode  }}
                                  </template>
                              </b-table>
                              -->
            </b-col>
            <b-col
              cols="12"
              style="margin-top:20px; margin-bottom:20px"
              v-else>
              <!-- untuk table yang dikomen tidak saya gunakan karena barcode yang ditampilkan adalah semua barcode yang ada di SAPDO tersebut, yang seharusnya hanya barcode yang akan di Un Scan saja -->

              <b-table
                :fields="itemFields"
                :items="doData.DO_details[selectIndex].scannedBarcode"
                show-empty
                responsive
                :stacked="stackedStatus">
                <template #cell(barcode)="row">
                  {{ row.item.itemUsed }}
                </template>
              </b-table>

              <!-- commenting this on, doesn't work

                              <b-table
                                  :fields="itemFields"
                                  :items="listItemScan"
                                  show-empty
                                  responsive
                                  :stacked= stackedStatus
                              >
                                  <template #cell(barcode)="row" >
                                      {{ row.item.scannedBarcode  }}
                                  </template>
                              </b-table>
                              -->
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="2"
              class="adjust-on-mobile">
              <b-button
                class="w-100 d-flex justify-content-center"
                @click="backPageTwo"
                variant="primary">Back</b-button>
            </b-col>
          </b-row>
          <!-- <b-col cols="4"> </b-col> -->
          <!-- </b-row> -->
        </b-col>
        <!-- page three -->
      </b-card-body>
    </b-card>
  </div>
</b-overlay>
</template>

<script>
import {
  mapActions
} from "vuex";
import axios from "@/axios";
import ZxingVue from "../../../../components/ZxingEdit.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import {
  dateFormat,
  sleep
} from "@/utils/utils.js";

// document.quangga = Quagga;

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // 'v-quagga': CustomQuanggaImage,
    // 'v-quagga': CustomQuanggaDemo,
    // 'v-quangga-image': CustomQuanggaImage,
    "zxing-scanner": ZxingVue,
  },
  props: ["id"],
  data() {
    return {
      stackedStatus: "md",
      //variabel for save detail DO
      doData: {},
      loading: false,
      //variabel for save movement data item at scan function
      scanItemTables: [],

      itemFields: [{
        key: "barcode",
        label: "Barcode"
      }],

      //image for scan item modal
      barcodeImage: require("@/assets/images/drcc/barcode.png"),
      manualImage: require("@/assets/images/drcc/manual.png"),
      daikinLogo: require("@/assets/images/drcc/daikin-logo.png"),

      //initiate active page 1 to 3
      pageone: true,
      pagetwo: false,
      pagethree: false,

      postal: [],
      itemCatalog: [],

      selectData: "", //this one variable for save item info after scan function
      selectScan: "", //this one variable for save item info after select item to prepare scanning
      selectIndex: 0, //same as selectData and selectScan, but makes more sense

      barcode: null, //this one variable for save barcode
      backendItems: [], //this one variable for collect all after scanning barcode

      //modal needed variable
      modalScan: false,
      startScan: false,
      modalManual: false,
      startManual: true,
      modalSkip: false,
      modalError:false,
      errorList:[],

      xStatus: false,
      summaryTemp: "",
      buttonSelect: false,
      getItemSummary: "",
      statusUnscan: false,
      testDoData: {},
      updateStatusButton: false,
      summaryReturn: "",
      count: null,
      cobaa: 0,
      countReturn: 0,
      verifyCobaa: 0,
      inventory: [],

      goBack: "", //this one variable to mark this page is opened from what table list?
      goBackStatus: "",

      //for editted&canceled handler
      modalHandler: false,
      handlerTitle: "This is a test",
      handlerMessage: "This Is a Test",
      isCanceled: false,
      isEdit: false,
    };
  },
  mounted() {
    this.goBack = this.$route.params.from;
    this.goBackStatus = this.$route.params.status;

  },
  created() {
    this.$store
      .dispatch("delivery/detailDelivery", {
        id: this.id,
      })
      .then((Detail) => {
        this.dataPopulate(Detail);
      })
      .catch((err) => {
        console.error(err);
      });
  },
  methods: {
    closeModal() {
      this.modalError = false;
      return true
    },
    handlerOnClick() {
      if (this.isCanceled) {
        this.toPage("Cancel Delivery")
      } else if (this.isEdit) {
        this.toPage("Edit")
      }

    },
    toPage(doStatus = "NA") {
      var statusQuery = (doStatus == "NA") ? this.goBackStatus : doStatus;
      if (this.goBack === "resend") {
        this.$router.push({
          path: "/retrigger-api",
          query: {
            status: statusQuery
          },
        });
      } else if (this.goBack === "redelivery-daikin") {
        this.$router.push({
          path: "/redelivery/daikin",
          query: {
            status: statusQuery
          },
        });
      } else if (this.goBack === "redelivery-sumitomo") {
        this.$router.push({
          path: "/redelivery/sumitomo",
          query: {
            status: statusQuery
          },
        });
      } else if (this.goBack === "return-daikin") {
        this.$router.push({
          path: "/return-delivery/daikin",
          query: {
            status: statusQuery
          },
        });
      } else if (this.goBack === "return-sumitomo") {
        this.$router.push({
          path: "/return-delivery/sumitomo",
          query: {
            status: statusQuery
          },
        });
      } else if (this.goBack === "do-daikin") {
        this.$router.push({
          path: "/delivery-orders/daikin",
          query: {
            status: statusQuery
          },
        });
      } else if (this.goBack === "do-sumitomo") {
        this.$router.push({
          path: "/delivery-orders/sumitomo",
          query: {
            status: statusQuery
          },
        });
      } else if (this.goBack === "sc-daikin") {
        this.$router.push({
          path: "/self-collect/daikin",
          query: {
            status: statusQuery
          }
        });
      } else if (this.goBack === "sc-sumitomo") {
        this.$router.push({
          path: "/self-collect/sumitomo",
          query: {
            status: statusQuery
          }
        });
      } else if (this.goBack === "shipping-daikin") {
        this.$router.push({
          path: "/shipping/daikin",
          query: {
            status: statusQuery
          }
        });
      } else if (this.goBack === "shipping-sumitomo") {
        this.$router.push({
          path: "/shipping/sumitomo",
          query: {
            status: statusQuery
          }
        });
      } else if (this.goBack === "manual-return-daikin") {
        this.$router.push({
          path: "/manual-return-delivery/daikin",
          query: {
            status: statusQuery
          },
        });
      } else if (this.goBack === "manual-return-sumitomo") {
        this.$router.push({
          path: "/manual-return-delivery/sumitomo",
          query: {
            status: statusQuery
          },
        });
      } else {
        this.$router.push(`/`);
        this.$bvToast.toast("Shipping Point Not Found", {
          title: "Warning",
          variant: "danger",
          solid: true,
        });
      }
    },
    getItems() {
      var idList = this.doData.DO_details.map(x => {
        return x.material
      });
      //temporary get api items catalog from axios
      var list = idList.join("|")
      console.info('getItems list ', list)
      axios
        .get("sales-and-purchase/drcc/items/multi?idList=" + list)
        .then((response) => {
          this.itemCatalog = response.data.data;
          // console.info(this.itemCatalog)
        })
        .catch((e) => {
          console.info(e);
        });
    },
    async openManualScan() {
      this.startManual = true;
      await sleep(100);
      document.getElementById("manualScan").focus();
    },
    dataPopulate(Detail) {
      let tableIndex = 0;
      this.doData = Detail;
      if (Detail.status == "Edit Delivery") {
        this.scanItemTables = Detail.DO_details.reduce((prev, next) => {
          console.info("hapus", next.scannedBarcode);
          console.info("hapus2", next.returnBarcode);
          let expandedQty = [];
          var barcodeDO = next.scannedBarcode.map((x) => {
            return x.itemUsed;
          });
          var unscanBarcodeDO = next.returnBarcode.map((x) => {
            return x.item;
          });
          for (let i = 0; i < next.qty; i++) {
            var tempBarcode, tempUnScan;

            //for original scan Barcode
            if (barcodeDO[i] === undefined) {
              tempBarcode = "";
            } else if (barcodeDO[i] === "Skip Scan") {
              tempBarcode = barcodeDO[i];
            } else {
              tempBarcode = barcodeDO[i];
            }

            console.info("tempBarcode", tempBarcode);
            //for list return barcode
            if (unscanBarcodeDO[i] === undefined) {
              tempUnScan = "";
            } else {
              tempUnScan = unscanBarcodeDO[i];
            }

            if (tempBarcode === "Skip Scan") {
              console.info("true");
              expandedQty.push({
                ...next,
                scannedBarcode: tempUnScan,
                returnBarcode: tempBarcode,
                qty: 1,
                index: tableIndex,
                skip: true,
              });
              tableIndex++;
            } else {
              console.info("next_mounted", next);
              // expandedQty.push({ ...next, scannedBarcode: tempUnScan, returnBarcode:tempBarcode, index: tableIndex, skip: false, })
              expandedQty.push({
                ...next,
                scannedBarcode: tempBarcode,
                returnBarcode: tempUnScan,
                index: tableIndex,
                skip: false,
              });
              tableIndex++;
            }
          }

          return [...prev, ...expandedQty];
        }, []);
      } else {
        this.scanItemTables = Detail.DO_details.reduce((prev, next) => {
          console.info("hapus", next.scannedBarcode);
          console.info("hapus2", next.returnBarcode);
          let expandedQty = [];
          var barcodeDO = next.scannedBarcode.map((x) => {
            return x.itemUsed;
          });
          var unscanBarcodeDO = next.returnBarcode.map((x) => {
            return x.item;
          });
          for (let i = 0; i < next.qty; i++) {
            var tempBarcode, tempUnScan;

            //for original scan Barcode
            if (barcodeDO[i] === undefined) {
              tempBarcode = "";
            } else {
              tempBarcode = barcodeDO[i];
            }

            //for list return barcode
            if (unscanBarcodeDO[i] === undefined) {
              tempUnScan = "";
            } else {
              tempUnScan = unscanBarcodeDO[i];
            }
            expandedQty.push({
              ...next,
              scannedBarcode: tempUnScan,
              returnBarcode: tempBarcode,
              qty: 1,
              index: tableIndex,
              skip: (tempBarcode == "Skip Scan"),
            });
            tableIndex++;
          }

          return [...prev, ...expandedQty];
        }, []);
      }
      console.info("this.scanItemTables_mounted", this.scanItemTables);
      

      // looping ini untuk menghitung berapa total keseluruhan barcode atau item yang akan di Return Unscan
      for (let i = 0; i < this.doData.DO_details.length; i++) {
        let qtyTemp = (this.doData.DO_details[i].qtyUpdate!=undefined)?this.doData.DO_details[i].qtyUpdate:this.doData.DO_details[i].qty

        this.countReturn =
          this.countReturn +
          (qtyTemp -
            this.doData.DO_details[i].returnBarcode.length);
      }

      console.info("this.countReturn", this.countReturn);
      console.info("this.doData", this.doData);
      this.testDoData = Detail;

      //v2
      // if updateStatusButton is true, button to press
      if (this.listToBeReturned.length > 0) {
        this.updateStatusButton = false;
      } else {
        this.updateStatusButton = true;
      }

      this.getItems()
    },
    dates(date) {
      return dateFormat(date);
    },
    ...mapActions({
      updateStatus: "delivery/updateDO",
      sendManualReturnToFTP: "delivery/sendManualReturnToFTP",
      sendReturnToFTP: "delivery/sendReturnToFTP",
      verifyScannedInventory:"delivery/verifyScannedInventory"
    }),
    showTime(priority) {
      var time;
      if (priority === "11") {
        time = "Morning: 9AM - 12PM";
      } else if (priority === "12") {
        time = "Afternoon: 1PM - 3PM";
      } else if (priority === "13") {
        time = "Afternoon: 3PM - 6PM";
      } else if (priority === "03") {
        time = "Self Collect AMK";
      } else if (priority === "05") {
        time = "Self Collect Sumitomo";
      } else {
        time = "";
      }
      return time;
    },
    selectItem(row) {
      console.info("this.selectScan", row.item);
      this.selectScan = row.item;
      this.pagetwo = false;
      this.pagethree = true;
    },
    //select by index - to comply with auto update after unscan
    selectItemByIndex(index) {
      this.selectIndex = index;
      this.selectScan = this.doData.DO_details[index];
      console.info("select scan index " + index + " ", this.selectScan);
      this.pagetwo = false;
      this.pagethree = true;
    },
    nextPage() {
      this.pageone = false;
      this.pagetwo = true;
      console.info('tobereturned ',this.listToBeReturned)
      console.info('toBeunscenned ',this.toBeUnscanned)
    },
    backPage() {
      this.pageone = true;
      this.pagetwo = false;
    },
    backPageTwo() {
      this.pagetwo = true;
      this.pagethree = false;
    },
    clearBarcode() {
      this.barcode = null;
    },
    
    async verifySerial() {
      this.loading = true
      console.info("VERIFY SERIAL TRIGGERED");

      let postal = "";
      let serial = "";
      var check = "";

      let localSerial = this.barcode.trim();
      localSerial = localSerial.replaceAll("*", "");

      postal = localSerial.substr(0, 6);
      serial = localSerial.substr(6, 8);

      if (this.doData.status === "Approved") {
        check = this.listToBeReturned.includes(this.barcode);
        if (check == false) {
          this.$bvToast.toast("Barcode is not in return list", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          return;
        }
      }

      var response;
      try {
        response = await axios.post(
          "sales-and-purchase/drcc/delivery/verify-unscan", {
            postal: postal,
            serial: serial,
            barcode: localSerial,
            material: this.selectData,
            sapDo: this.doData.sapDo,
            idDO: this.id,
          }
        )
        this.loading = false
      } catch (err) {
        this.loading = false
        this.barcode = null;
        if (err.response.data !== undefined) {
          this.$bvToast.toast(err.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        } else {
          this.$bvToast.toast("Connection error. Please refresh the page and try again", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        }
        return null;
      }
      this.$bvToast.toast("Success Unscan Barcode", {
        title: "Success",
        variant: "success",
        solid: true,
      });
      // this.barcode = null;
      this.buttonSelect = true;
      this.modalManual = false;
      this.modalScan = false;

      var updateDOData = response.data.data;

      this.dataPopulate(updateDOData);
      //what?? - WHO ADDED THIS SHIT? - THIS CAUSED ERROR
      //this.listToBeReturned();

      this.xStatus = true;
      console.info("this.listToBeReturned", this.listToBeReturned);
      return this.listToBeReturned;
      // }
    },
    async verifySkip() {
      console.info("VERIFY Skip TRIGGERED");
      console.info(' this.selectData.material', this.selectData.material);
      console.info(' sapDo', this.doData.sapDo);
      console.info(' idDO', this.id);
      this.loading = true

      var response;
      try {
        response = await axios.post(
          "sales-and-purchase/drcc/delivery/skip-unscan", {
            material: this.selectData.material,
            sapDo: this.doData.sapDo,
            idDO: this.id,
          }
        )
      } catch (err) {
        this.loading = false
        this.barcode = null;
        if (err.response.data !== undefined) {
          this.$bvToast.toast(err.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        } else {
          this.$bvToast.toast("Connection error. Please refresh the page and try again", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        }
        return null;
      }
      this.loading = false

      this.$bvToast.toast("Success Skip Unscan Barcode", {
        title: "Success",
        variant: "success",
        solid: true,
      });
      // this.barcode = null;
      this.buttonSelect = true;
      this.modalManual = false;
      this.modalScan = false;
      this.modalSkip = false;

      var updateDOData = response.data.data;
      console.info("updateDOData", updateDOData);
      console.info("response", response);

      this.dataPopulate(updateDOData);

      this.xStatus = true;
      return true;
    },
    editEnabled() {
      // bagian ini untuk mengatur kondisi Button Edit Completed

      // scanIndex digunakan untuk mencari index barang yang sudah di Un Scan dari this.scanItemTables
      let scanIndex = this.scanItemTables.findIndex((item) => {
        return item.scannedBarcode == "" && item.returnBarcode == "";
      });
      if (scanIndex < 0) {} else {
        let cloneItemTable = JSON.parse(JSON.stringify(this.scanItemTables));
        // let items = { ...cloneItemTable[scanIndex], returnBarcode : this.barcode };
        // console.info('items', items)

        this.scannedBarcode = null;

        // hapus array sesuai dari scanIndex
        cloneItemTable.splice(scanIndex, 1);

        // Replace
        this.scanItemTables = cloneItemTable;
      }

      // this method to return new total quantity
      let count = 0;
      for (var i = 0; i < this.doData.DO_details.length; i++) {
        let DO_temp = this.doData.DO_details[i];
        count += DO_temp.qtyUpdate;
      }
      //console.info('this.scan ',this.scanItemTables)
      //console.info("count", count);
      console.info("scanItemTables.length ", this.scanItemTables.length)
      return this.scanItemTables.length != 0 && count!=this.scanItemTables.length;
    },
    async scanComplete() {
      var tempStatus, tempMark;

      this.loading = true

      try{
        var inventoryCheck = await this.verifyScannedInventory(this.doData.sapDo)
        if(inventoryCheck.length>0){
          this.errorList=inventoryCheck;
          this.modalError = true
          this.loading = false;
          return;
        }
        
      }catch(err){
        console.log({
          err
        });
        if (err.response.data !== undefined) {
          this.$bvToast.toast(err.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        } else {
          this.$bvToast.toast("Connection error. Please refresh the page and try again", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        }
        this.loading = false;
        return;
      }

      if (this.doData.status === "Approved") {
        tempStatus = "Return Completed";
        tempMark = this.doData.mark;
      } else {
        tempStatus = "Scanning Completed";
        tempMark = "";
      }

      console.info({
        status: tempStatus,
        mark: tempMark
      });

      this.updateStatus({
          idDelivery: this.id,
          deliveryData: {
            status: tempStatus,
            mark: tempMark
          },
        })
        .then(async (res) => {
          if (res.draftID != null) {
            this.isEdit = true;
            this.loading = false
            this.handlerMessage = "This DO #" + this.doData.sapDo + " scanning has been completed." +
              " There is an Edit DO for this DO. Please proceed to process the Edit DO"
            this.$bvToast.toast(
              "An edit was detected, changing DO status to Edit", {
                title: "Warning",
                variant: "danger",
                solid: true,
              }
            );
            this.modalHandler = true;
          } else if (res.canceled) {
            this.isCanceled = true;
            this.loading = false
            this.handlerMessage = "This DO #" + this.doData.sapDo + " scanning has been completed." +
              " There is an Cancel DO for this DO. Please proceed to process the Cancel DO"
            this.$bvToast.toast(
              "An edit was detected, changing DO status to Edit", {
                title: "Warning",
                variant: "danger",
                solid: true,
              }
            );
            this.modalHandler = true;
          } else {
            //success status for Edit Delivery
            if (
              this.doData.status === "Edit Delivery" ||
              this.doData.status === "Edit in Progress"
            ) {
              this.loading = false
              this.$bvToast.toast("Edit DO is Completed", {
                title: "Success",
                variant: "success",
                solid: true,
              });
            }
            //success status for return
            if (this.doData.status === "Approved") {
              this.loading = false
              this.$bvToast.toast("Return DO Completed", {
                title: "Success",
                variant: "success",
                solid: true,
              });
              var ftpReturn;

              try {
                if (this.doData.manualReturnFlag >= 1) {
                  ftpReturn = await this.sendManualReturnToFTP(this.doData._id);
                } else {
                  ftpReturn = await this.sendReturnToFTP(this.doData._id)
                }

              } catch (err) {
                this.loading = false;
                this.$bvToast.toast("Send Return PDF to FTP Failed", {
                  title: "Failed",
                  variant: "danger",
                  solid: true,
                })
              }

            }
            await sleep(2000);
            this.goBackCall();
          }

        })
        .catch((e) => {
          console.info(e);
          this.loading = false
          if (e.response.data !== undefined) {
            this.$bvToast.toast(e.response.data.message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast("Connection error. Please refresh the page and try again", {
              title: "Failed",
              variant: "danger",
              solid: true,
            });

          }
        });
      await sleep(1000);
      this.goBackCall();
    },
    goBackCall() {
      if (this.goBack === "do-daikin") {
        this.$router.push({
          path: "/delivery-orders/daikin",
          query: {
            status: "Scanning Completed"
          },
        });
      } else if (this.goBack === "do-sumitomo") {
        this.$router.push({
          path: "/delivery-orders/sumitomo",
          query: {
            status: "Scanning Completed"
          },
        });
      } else if (this.goBack === "sc-daikin") {
        this.$router.push(`/self-collect/sumitomo`);
      } else if (this.goBack === "sc-sumitomo") {
        this.$router.push(`/self-collect/sumitomo`);
      } else if (this.goBack === "shipping") {
        this.$router.push(`/shipping`);
      } else if (this.goBack === "return-sumitomo") {
        this.$router.push({
          path: "/return-delivery/sumitomo",
          query: {
            status: "Return Completed"
          },
        });
      } else if (this.goBack === "return-daikin") {
        this.$router.push({
          path: "/return-delivery/sumitomo",
          query: {
            status: "Return Completed"
          },
        });
      } else if (this.goBack === "manual-return-sumitomo") {
        this.$router.push({
          path: "/manual-return-delivery/sumitomo",
          query: {
            status: "Return Completed"
          },
        });
      } else if (this.goBack === "manual-return-daikin") {
        this.$router.push({
          path: "/manual-return-delivery/sumitomo",
          query: {
            status: "Return Completed"
          },
        });
      } else {
        this.$router.push(`/`);
        this.$bvToast.toast("Shipping Point Not Found", {
          title: "Warning",
          variant: "danger",
          solid: true,
        });
      }
    },
    doUnscan(material) {
      //  var searchPostcode = this.postal.find(x=>{return x.itemModel==material});
      //   if(searchPostcode!=undefined){

      this.selectData = material;

      let scanIndex = this.scanItemTables.findIndex((item) => {
        return item.skip === false && item.material === material;
      });

      if (scanIndex < 0) {
        this.$bvToast.toast("No More Unscan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } else {
        // this.modalManual = true;
        this.startScan = true;
        this.modalScan = true; // this is for scan from browser
      }

      // }else{
      //     this.$bvToast.toast('This Item does not have Post Code', {
      //     title: "Error",
      //     variant: "danger",
      //     solid: true,
      //     });
      // }
    },
    testDoUnscan(material) {
      //console.info('material_testDoUnscan', material)

      //  var searchPostcode = this.postal.find(x=>{return x.itemModel==material});
      //   if(searchPostcode!=undefined){

      this.selectData = material;

      let scanIndex = this.scanItemTables.findIndex((item) => {
        return item.skip === false && item.material === material;
      });

      if (scanIndex < 0) {
        this.$bvToast.toast("No More Unscan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } else {
        this.modalManual = true;
        this.openManualScan();
      }

      // }else{
      //     this.$bvToast.toast('This Item does not have Post Code', {
      //     title: "Error",
      //     variant: "danger",
      //     solid: true,
      //     });
      // }
    },
    skipUnscan(data) {

      this.selectData = data;
      console.info('scan item tables ', this.scanItemTables)

      let scanIndex = this.scanItemTables.findIndex((item) => {
        // return item.skip === true && item.material === data.item.material
        return item.skip === true && item.material === data.material;
      });
      console.info("scanIdex_skipUnscan", scanIndex);

      if (scanIndex < 0) {
        this.$bvToast.toast("No More Skip Unscan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } else {
        this.modalSkip = true;
      }

      // }else{
      //     this.$bvToast.toast('This Item does not have Post Code', {
      //     title: "Error",
      //     variant: "danger",
      //     solid: true,
      //     });
      // }
    },
    getSummary(material) {
      let itemSummary = this.summary[material];
      // console.info('this.count_getSummary',this.summary)
      console.info("this.count", this.count);
      if(itemSummary==undefined){
        console.info('item summary is undefined ',material);
        itemSummary={scanQty:0}
      }
      return itemSummary;
    },
    //Used specifically for Return DO, do not remove or change without permission
    getReturnSummary(material) {
      var searchitem = this.doData.DO_details.find((x) => {
        return x.material === material;
      });
      if (searchitem==undefined){
        return {
        qty: 0,
        toUnscan: 0,
        unscanned: 0
      };
      }
      var result = 0;

      //console.info('searcitem_getEdit', searchitem)
      var qty =
        searchitem.qtyUpdate !== undefined ?
        searchitem.qtyUpdate :
        searchitem.qty;
      var toUnscan = searchitem.returnBarcode.length;
      var unscanned = qty - searchitem.scannedBarcode.length;

      //if do is return

      //console.info('result_getEditSummary', result)
      return {
        qty: qty,
        toUnscan: toUnscan,
        unscanned: unscanned
      };
    },
    //Used specifically for Edit DO, do not remove or change without permission
    getEditSummary(material) {
      var searchitem = this.testDoData.DO_details.find((x) => {
        return x.material === material;
      });
      var result = 0;

      //console.info('searcitem_getEdit', searchitem)

      //if do is return
      if (this.doData.status === "Approved") {
        if (searchitem.qty === this.summaryReturn[material].scanQty) {
          //for return
          result = 0;
        } else {
          //for return do
          result = searchitem.qty - this.summaryReturn[material].scanQty;
        }
      } else {
        //edit do
        if (searchitem.qtyUpdate != undefined) {
          //for edit do
          //console.info('if_searchitem_getEdit', searchitem)
          result = searchitem.qty - searchitem.scannedBarcode.length;
        }
      }
      //console.info('result_getEditSummary', result)
      //scanQty == number of unscanned barcode
      return {
        scanQty: result
      };
    },
    getItem(data) {
      var searchItem = this.itemCatalog.find((x) => {
        return x._id == data;
      });
      var result;
      if (searchItem === undefined) {
        result = {
          itemModel: " ",
        };
      } else {
        result = searchItem;
      }
      return result;
    },
  },
  computed: {
    disableReturnCompleteButton(){
      var status = true;
      var finalQuantityRequired = this.countReturn;
      var currentQuantity = this.scanItemTables.length;
      var unscannedQuantity = this.scanItemTables.filter((x)=>{
        return x.returnBarcode === ''
      }).length
      console.info('f '+finalQuantityRequired,'c '+currentQuantity,'u '+unscannedQuantity)
      status = !(finalQuantityRequired===(currentQuantity-unscannedQuantity))
      return status
    },
    scanFields() {
      console.info(this.doData.DO_details)
      if (this.doData.status == "Approved") {
        return [{
            key: "material",
            label: "Item Model"
          },
          {
            key: "desc",
            label: "Description"
          },
          {
            key: "qty",
            label: "DO Qty"
          },
          {
            key: "newQty",
            label: "To Un Scan"
          },
          {
            key: "scan",
            label: "Unscanned"
          },
          {
            key: "actions",
            label: "Action"
          },
        ];
      } else {
        return [{
            key: "material",
            label: "Item Model"
          },
          {
            key: "desc",
            label: "Description"
          },
          {
            key: "qty",
            label: "Edited DO Qty"
          },
          {
            key: "newQty",
            label: "To Un Scan"
          },
          {
            key: "scan",
            label: "Unscanned"
          },
          {
            key: "actions",
            label: "Action"
          },
        ];
      }
    },
    summary() {
      console.info("=============================================");
      if (this.doData.status == "Edit Delivery") {
        var temp = {};
        for (var i = 0; i < this.doData.DO_details.length; i++) {
          let DO_temp = this.doData.DO_details[i];
          temp[DO_temp.material] = {
            scanQty: DO_temp.qty - DO_temp.scannedBarcode.length,
          };
        }
        return temp;
      } else {
        return this.scanItemTables.reduce((prev, next) => {
          if (prev[next.material] === undefined) {
            prev[next.material] = {
              scanQty: 0,
              emptyQty: 0
            };
          }

          if (next.returnBarcode == "" && next.scannedBarcode == "") {
            console.info(
              'next.returnBarcode == "" && next.scannedBarcode == ""'
            );

            if (next.returnBarcode === "") {
              prev[next.material].scanQty += 1;
            } else {
              prev[next.material].emptyQty += 1;
            }
          }

          if (next.returnBarcode == "" && next.scannedBarcode !== "") {
            console.info(
              'next.returnBarcode == "" && next.scannedBarcode !== ""'
            );

            if (next.returnBarcode !== "") {
              prev[next.material].emptyQty += 1;
            } else {
              prev[next.material].scanQty += 1;
            }
          }

          if (next.scannedBarcode == "" && next.returnBarcode !== "") {
            console.info("next.material", prev[next.material]);

            if (next.returnBarcode !== "") {
              prev[next.material].emptyQty += 1;
            } else {
              prev[next.material].scanQty += 1;
            }
          }
          if (prev[next.material].scanQty !== 0) {
            this.count += 1;
          }
          console.info(
            "prev[next.material].scanQty",
            prev[next.material].scanQty
          );
          this.summaryReturn = prev;
          return prev;
        }, {});
      }
    },
    availability() {
      //keep function to update for unpick complete.
      var searchBarcode = this.scanItemTables.map((element) => {
        return element.returnBarcode;
      });

      let searchIndex = searchBarcode.findIndex((x) => {
        return x != "";
      });

      var nextStep = false;
      if (searchIndex >= 0) {
        nextStep = false;
      } else {
        nextStep = true;
      }

      return nextStep;
    },
    listToBeReturned() {
      //V2  of listItemScan
      var returnData = this.doData.DO_details[this.selectIndex];
      let scannedBarcode = returnData.scannedBarcode.map((x) => {
        return x.itemUsed;
      }); //list of all scanned barcode
      console.info('scannedBarcode', scannedBarcode);
      let returnBarcode = returnData.returnBarcode.map((x) => {
        return x.item;
      }); //list of all returned barcode

      //finds barcode in returnBarcode that is still in scannedBarcode
      var values
      let toBeUnscanned = scannedBarcode.filter((value) => {
        values = value
        //console.info('value', value);
        //console.info('returnBarcode', returnBarcode);

        return returnBarcode.includes(value);
      });

      if (values === 'Skip Scan') {
        const skipScanCount =
          this.getReturnSummary(this.selectScan.material).toUnscan -
          this.getReturnSummary(this.selectScan.material).unscanned;

        if (skipScanCount <= 0) {
          toBeUnscanned = []; // Return an empty array
        } else {
          const skipScanArray = new Array(skipScanCount).fill('Skip Scan');
          toBeUnscanned = skipScanArray;
        }
      }

      console.info("toBeUnscanned", toBeUnscanned);
      return toBeUnscanned;
    },
    listItemScan() {
      // var result = this.scanItemTables.filter(x=>{return x.material===this.selectScan.material})
      console.info("*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
      console.info("this.scanItemTables", this.scanItemTables);
      console.info("this.selectScan", this.selectScan);
      // console.info('*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&')
      var result = this.scanItemTables.filter((x) => {
        let v = this.inventory.find((el) => {
          if (el.barcode) {
            //WTF? - THIS WILL ALWAYS RETURN ERROR
            return (
              el.barcode == x.scannedBarcode &&
              el.sapDo == this.doData.sapDo &&
              //WHY???
              this.selectScan.material === x.material
            );
          }
        });
        // return x.material===this.selectScan.material

        /**
                    this part can just be replaced to

                    return v , conditional is required
                */
        if (v != undefined) {
          return v; //
        } else {
          //Ahmad - added else handler, as it will return error in filter if v is infact, undefined
          return false;
          console.info("v", v);
          console.info("*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
        }
      });
      //console.info('this.tempDataList', this.tempDataList)
      if (this.xStatus) {
        let scanIndex = this.scanItemTables.findIndex((item) => {
          return (
            item.scannedBarcode !== this.barcode &&
            item.material === this.selectData
          );
        });
        if (scanIndex < 0) {} else {
          let cloneItemTable = JSON.parse(JSON.stringify(this.scanItemTables));
          let items = {
            ...cloneItemTable[scanIndex],
            returnBarcode: this.barcode,
          };
          console.info("items", items);

          this.scannedBarcode = null;

          cloneItemTable.splice(scanIndex, 1, items);
          // Replace
          this.scanItemTables = cloneItemTable;
          // console.info('this.scanItemTables_listItemScan', this.listItemScan)
        }
        this.xStatus = false;
      }

      if (this.doData.status === "Edit Delivery") {
        var cob = result.filter((x) => {
          // return x.scannedBarcode !== "" && x.returnBarcode !=""
          return x.qty != x.qtyUpdate && x.scannedBarcode != "";
        });
        console.info("cob ", cob);
        console.info("result ", result);
        return cob;
      } else {
        // return cob
        return result;
      }
    },
    btnScan() {
      // used as :disable on scan button
      // if number of unscanned is less than toUnscan, returns false
      // else (implicitly if unscanned is equal to toUnscan), returns true
      //check if material is skip
      var getSkip = this.listItemScan.map((get) => {
        return get.skip;
      });

      //count scan
      var countUnscan =
        this.doData.status == "Approved" ?
        this.getReturnSummary(this.selectScan.material).unscanned :
        this.getEditSummary(this.selectScan.material).scanQty;
      var toBeUnscanned =
        this.doData.status == "Approved" ?
        this.getReturnSummary(this.selectScan.material).toUnscan :
        this.selectScan.qty - this.selectScan.qtyUpdate;
      console.info("count scan ", countUnscan);
      console.info("toBe unscanned ", toBeUnscanned);
      if (countUnscan < toBeUnscanned) {
        return false;
      } else {
        return true;
      }
    },
    btnSkip() {
      // used as :disable on scan button
      // if number of unscanned is less than toUnscan, returns false
      // else (implicitly if unscanned is equal to toUnscan), returns true
      var getSkip = this.listItemScan.map((get) => {
        return get.skip;
      });

      //number of unscanned done
      //if do is return, get from summary.scanQty
      //if do is edit, get from get edit Summary
      //count scan
      var countUnscan =
        this.doData.status == "Approved" ?
        this.getReturnSummary(this.selectScan.material).unscanned :
        this.getEditSummary(this.selectScan.material).scanQty;
      var toBeUnscanned =
        this.doData.status == "Approved" ?
        this.getReturnSummary(this.selectScan.material).toUnscan :
        this.selectScan.qty - this.selectScan.qtyUpdate;
      console.info("count scan ", countUnscan);
      console.info("toBe unscanned ", toBeUnscanned);
      if (countUnscan < toBeUnscanned) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.hide-at-all {
  display: none !important;
}

/* For mobile phones: */
@media (max-width: 761px) {
  .adjust-on-mobile {
    margin-bottom: 10px !important;
  }

  .center-on-mobile {
    align-items: center !important;
  }

  .paging-center-mobile {
    justify-content: center !important;
  }
}

@media (min-width: 761px) {
  .mobile-version {
    display: none !important;
  }
}

@media (max-width: 760px) {
  .desktop-version {
    display: none !important;
  }
}
</style>
